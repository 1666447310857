import HijackIndicator from "SKNUI/hijack/hijack-indicator";
import { renderComponentToDOM } from "SKNUI/scripts/utils";

renderComponentToDOM(HijackIndicator, "org_hijack_toast", {
  isOrgHijack: true,
});

renderComponentToDOM(HijackIndicator, "student_hijack_toast", {
  isOrgHijack: false,
});
