import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { User } from "SKNUI/interfaces/user";
import ToastEndHijack from "./toast-end-hijack";

interface Props {
  user: User;
  isOrgHijack: boolean;
}

export default function HijackIndicator({ user, isOrgHijack }: Props) {
  useEffect(() => {
    toast(<ToastEndHijack user={user} isOrgHijack={isOrgHijack} />);
  }, []);

  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        className: "toast--end-hijack",
        duration: Infinity,
        style: {
          textAlign: "center",
          maxWidth: "75vw",
        },
      }}
    />
  );
}
