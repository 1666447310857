import { User } from "SKNUI/interfaces/user";

interface Props {
  user: User;
  isOrgHijack: boolean;
}

export default function ToastEndHijack({ user, isOrgHijack }: Props) {
  const url = isOrgHijack
    ? "/member-hijack/organization/leave/"
    : "/member-hijack/student/leave/";

  return (
    <div className="toast--end-hijack">
      <span>
        Viewing as <strong>{user.fullName}</strong>{" "}
        <a className="alans-butt--purple" href={url}>
          Exit
        </a>
      </span>
    </div>
  );
}
