// Webpack Polyfill Injector
function main() {
    require("./static/development/scripts/entries/hijack-user.entry.tsx");
}
var polyfills = function() {
    return [
        /* CustomEvent */ ("CustomEvent"in self&&("function"==typeof self.CustomEvent||self.CustomEvent.toString().indexOf("CustomEventConstructor")>-1)
) ? 0 : 1,
        /* Element.prototype.closest */ ("document"in self&&"closest"in document.documentElement
) ? 0 : 1,
        /* Object.assign */ ("assign"in Object
) ? 0 : 1,
        /* Promise */ ("Promise"in self
) ? 0 : 1,
        /* String.prototype.startsWith */ ("startsWith"in String.prototype
) ? 0 : 1,
        /* fetch */ ("fetch"in self
) ? 0 : 1
    ];
}.call(window);
if (polyfills.indexOf(1) === -1) {
    main();
} else {
    var js = document.createElement('script');
    js.src = "/static/compiled/scripts/polyfills.bundle." + polyfills.join('') + '.js';
    js.onload = main;
    js.onerror = function onError(message) {
        console.error('Could not load the polyfills: ' + message);
    };
    document.head.appendChild(js);
}